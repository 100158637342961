import React from "react"
import "./index.css"

export const Footer = () => {
  return (
    <footer>
      <div>
        © <span className="name">DIM SUM CLUB INC.</span> {new Date().getFullYear()}
      </div>
    </footer>
  )
}
